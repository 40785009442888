import React from 'react';
import Navbar from './Navbar'
import Footer from './Footer';
import { TbApi } from "react-icons/tb";



function Home() {


  
  return (
    <div>
        <Navbar />
        <div className='App-header1'>  
        <section class="py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h3 class="fs-6 text-secondary mb-2 text-uppercase text-center">About Us</h3>
        <h2 class="mb-4 display-5 text-center">Our Expertise</h2>
        <p class="fs-5 text-secondary mb-5 text-center">Our clients are our top priority, and we are committed to providing them with the highest level of service.</p>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container overflow-hidden">
    <div class="row gy-4">
    <div class="col-6 col-md-4 col-xl-3 text-center">
  <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
    <h3 class="">Web Development</h3>
  </div>
</div>




<div class="col-6 col-md-4 col-xl-3 text-center">
  <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
    <h3 class="">UI/UX Design</h3>
  </div>
</div>
<div class="col-6 col-md-4 col-xl-3 text-center">
  <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
    <h3 class="">Web Hosting</h3>
  </div>
</div>

<div class="col-6 col-md-4 col-xl-3 text-center">
  <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
    <h3 class="">User Authentication</h3>
  </div>
</div>

<div class="col-6 col-md-4 col-xl-3 text-center">
  <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
    <h3 class="">Mobile Development</h3>
  </div>
</div>
<div class="col-6 col-md-4 col-xl-3 text-center">
  <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
    <h3 class="">QA & Testing</h3>
  </div>
</div>
<div class="col-6 col-md-4 col-xl-3 text-center">
  <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
    <h3 class="">API Integration</h3>
  </div>
</div>
<div class="col-6 col-md-4 col-xl-3 text-center">
  <div class="text-secondary bg-light px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5 hover-logo">
    <h3 class=""> Database Modeling</h3>
  </div>
</div>



    </div>
  </div>
</section>        </div>
        <Footer />
    </div>
  );
}

export default Home;
