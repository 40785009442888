import React, { useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import hero from './hero.webp';

function Home() {
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent scrolling
    return () => {
      document.body.style.overflow = 'unset'; // Re-enable scrolling when component unmounts
    };
  }, []);

  return (
    <section className="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull vh-100" style={{ backgroundImage: `url(${hero})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="container h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7 text-center text-white">
            <h1 className="display-3 fw-bold mb-3">DNG Solutions</h1>
            <p className="lead mb-5">Welcome to DNG Solutions, an IT solutions and consulting firm.</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <Link to='/contact' type="button" className="btn bsb-btn-3xl btn-light gap-3">Free Consultation </Link>
              <Link to='/about' className="btn bsb-btn-3xl btn-outline-light">Learn More</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
