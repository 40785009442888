import React, { useEffect } from 'react';

const Ad = ({ slot }) => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div>
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-6723283370071776"  // Your client ID here
      data-ad-slot={slot}  // Your specific slot ID goes here
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
        <div className='container'>
      <h1 className='text-center'> Ad component </h1>
    </div>
    </div>

  );
};

export default Ad;
