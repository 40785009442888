// login.js
import React, { useEffect, useState } from 'react';
import { auth, provider } from './firebase'; // Import the auth and provider objects from firebase.js
import { signInWithPopup, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Login() {

    const [email, setEmail] = useState('');
    const navigate = useNavigate()

    const handleClickSignIn = () => {
        signInWithPopup(auth, provider)
        .then((result) => {
            const userEmail = result.user.email;
            setEmail(userEmail);
            localStorage.setItem("email", userEmail); // Corrected syntax
            navigate('/');

        })
        .catch((error) => {
            console.error("Error signing in with Google:", error);
        });
    };

    const handleClickSignOut = () => {
        signOut(auth)
        .then(() => {
            setEmail('');
            localStorage.removeItem("email");
        })
        .catch((error) => {
            console.error("Error signing out:", error);
        });
    };

    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []); // Added dependency array to prevent unnecessary re-renders

    return (
        <div className='text-center'>
            <h2>Login</h2>
            {email ? 
                <div>
                    <p>Signed in as: {email}</p>
                    <button onClick={handleClickSignOut}>Sign Out</button>
                </div>
                :
                <div className='container col-lg-4'>
                <a href="#!" class="btn bsb-btn-2xl btn-outline-dark rounded-0 d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google text-danger" viewBox="0 0 16 16">
                  <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                </svg>
                <span onClick={handleClickSignIn} class="ms-2 fs-6 flex-grow-1">Continue with Google</span>
              </a>
              </div>
            }
        </div>
    );
}

export default Login;
