import logo from './logo.svg';
import './App.css';
import { NavLink, Link } from 'react-router-dom';


function Navbar() {
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <Link to='/' class="navbar-brand underline" href='/'>DNG</Link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
          <NavLink exact activeClassName="active" className="nav-link underline" to="/about">About</NavLink>
          </li>
          <li class="nav-item">
          <NavLink exact activeClassName="active" className="nav-link  underline" to="/contact">Contact</NavLink>
          </li>
        </ul>
    
      </div>
    </div>
  </nav>

  );
}

export default Navbar;
