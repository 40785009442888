import React from 'react';
import Navbar from './Navbar'
import Footer from './Footer';


function Projects() {
  return (
    <div>
        <Navbar />
        <div className='App-header'>  
        <h3 className='text-center text-dark'>Projects Page</h3>

        </div>
        <Footer />
    </div>
  );
}

export default Projects;
