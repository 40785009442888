import React from 'react'
import dng from './dng.png'

function Footer() {
  return (
    <footer class="footer" style={{backgroundColor: '#f2f2f2'}}>
  
    <div class=" py-3 py-md-5 py-xl-8 border-top border-light-subtle">
      <div class="container overflow-hidden">
        <div class="row gy-3 gy-md-5 gy-xl-0 align-items-sm-center">
          <div class="col-xs-12 col-sm-6 col-xl-3 order-0 order-xl-0">
            <div class="footer-logo-wrapper text-center text-sm-start">
              <a href="#!">
                <img src={dng} alt="DNG Logo" width="130" height="130"/>
              </a>
            </div>
          </div>
  
          <div class="col-xs-12 col-xl-6 order-2 order-xl-1">
            <ul class="nav justify-content-center">
              <li class="nav-item">
                <a class="nav-link link-secondary px-2 px-md-3" href="/">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link link-secondary px-2 px-md-3" href="/about">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link link-secondary px-2 px-md-3" href="/contact">Contact</a>
              </li>
       
          
            </ul>
          </div>
  
          <div class="col-xs-12 col-sm-6 col-xl-3 order-1 order-xl-2">
            <ul class="nav justify-content-center justify-content-sm-end">
             <li class="nav-item">
                <a class="nav-link link-dark px-3" href="https://www.linkedin.com/company/dng-solutions-linkedin/about/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                  </svg>
                </a>
              </li>
          
              <li class="nav-item">
  <a class="nav-link link-dark px-3" href="mailto:dngsolutions@outlook.com">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
    </svg>
  </a>
</li>

            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class=" py-3 py-md-5 border-top border-light-subtle">
      <div class="container overflow-hidden">
        <div class="row">
          <div class="col">
            <div class="footer-copyright-wrapper text-center">
              &copy; 2024. All Rights Reserved.
            </div>
            <div class="credits text-secondary text-center mt-2 fs-7">
              Built by <a href="https://dngsolution.com/" class="link-secondary text-decoration-none">DNG Solutions</a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </footer>  )
}

export default Footer